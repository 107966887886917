import Vue from "vue";
import Vuex from "vuex";
import record from '../views/pages/Record/store'
import patrol from '../views/pages/Patrol/store'
import checkSelf from '../views/pages/Checkself/store'
import user from '../views/pages/Employees/store'
import sample from '../views/pages/Sample/store'
import department from '../views/pages/Department/store'
import menu from '../views/pages/Menu/store'
import carManage from '@/views/pages/CarManage/store'
import goodsManage from '@/views/pages/GoodsManage/store'
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  modules: {
    record,
    patrol,
    checkSelf,
    user,
    sample,
    department,
    menu,
    carManage,
    goodsManage
  },
  strict: debug
})


