

<template>
    <div class="wrap-container sn-container">
        <div class="sn-content">
            <div style="font-size: 20px;position: absolute;left: 50px;top:30px">日期:{{time}}</div>
            <div class='box-title'>

                <span v-if="type === 0" class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">收益详情分析</dv-decoration-11></span>
                <span v-else class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">成本详情分析</dv-decoration-11></span>
            </div>
            <div class="c-head">
                <div class="df1">
                    <div @click="changeType(0)" class="box-btn bgc-3EA">

                    </div>
                    <div>
                        收益
                    </div>
                </div>
                <div class="df">
                    <div @click="changeType(1)" class="box-btn bgc-956">

                    </div>
                    <div>
                        成本
                    </div>
                </div>
            </div>
            <div class="sn-body1">
                <div class="wrap-container">
                    <div class="chartsdom" id="chart_bp"></div>
                </div>
            </div>
        </div>
        <div class="t-dialog" style="width: 1400px;height: 800px; margin-left: -700px;z-index: 999" v-if="showLine">
            <div class="p50 record-bg" style="width: 1400px;height: 800px;">
                <el-popover
                        placement="bottom"
                        width="100%"
                        trigger="click"
                        v-model="showPopover"
                        transition="el-zoom-in-top"
                >
                    <div style="padding: 20px">
                        <div class="p15 tc f16">
                            时间筛选
                        </div>
                        <div>
                            <el-date-picker
                                    v-model="value"
                                    type="datetimerange"
                                    align="right"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="pickerOptions">
                            </el-date-picker>
                        </div>
                        <div class="mt10 tr">
                            <button class="btn-reset " @click="timeConfirm(0)">
                                重置
                            </button>
                            <button class="btn-add" @click="timeConfirm(1)">
                                确定
                            </button>
                        </div>
                    </div>
                    <!--          <el-button slot="reference">click 激活</el-button>-->
                    <div slot="reference" class="box-i-time1">
                        <i  class="el-icon-time i-time"></i>
                    </div>
                </el-popover>
                <div class="sn-body1" style="width: 1400px;height: 800px;">
                    <div class="wrap-container">
                        <div style="width: 1400px;height: 800px;" id="chart_line"></div>
                    </div>
                </div>
            </div>
            <div class="t-close" @click="showLine = false,showPopover = false">×</div>
        </div>
    </div>
</template>

<script>
    import api from '@/util/extra-api'
    import bus from '@/util/bus'
    import {formatTime} from '../../util/util'
    let echarts = require('echarts/lib/echarts');
    // 引入柱状图
    require('echarts/lib/chart/bar');
    require('echarts/lib/chart/pie');

    let itemStyleList = [
        {
            color: '#7bbfea'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },
        {
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#faa755'
        }, {
            color: '#b2d235'
        }, {
            color: '#d6d638'
        },{
            color: '#07d8ff'
        },{
            color: '#faa755'
        },{
            color: '#0c71cf'
        },{
            color: '#00ae9d'
        },
        {
            color: '#7bbfea'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },
        {
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#dea32c'
        },{
            color: '#07d8ff'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#07d8ff'
        },{
            color: '#0c71cf'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#7bbfea'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },
        {
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#faa755'
        }, {
            color: '#b2d235'
        }, {
            color: '#d6d638'
        },{
            color: '#07d8ff'
        },{
            color: '#faa755'
        },{
            color: '#0c71cf'
        },{
            color: '#00ae9d'
        },
        {
            color: '#7bbfea'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },
        {
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#dea32c'
        },{
            color: '#07d8ff'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#07d8ff'
        },{
            color: '#0c71cf'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#7bbfea'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },
        {
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#faa755'
        }, {
            color: '#b2d235'
        }, {
            color: '#d6d638'
        },{
            color: '#07d8ff'
        },{
            color: '#faa755'
        },{
            color: '#0c71cf'
        },{
            color: '#00ae9d'
        },
        {
            color: '#7bbfea'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },
        {
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#dea32c'
        },{
            color: '#07d8ff'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#07d8ff'
        },{
            color: '#0c71cf'
        },{
            color: '#d6d638'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },{
            color: '#07d8ff'
        },{
            color: '#00c86c'
        },{
            color: '#d6d638'
        },{
            color: '#0c71cf'
        },
    ]
    export default {
        name: "szLinkage",
        data() {
            return {
                showLine:false,
                option: null,
                dataMap: {},
                list: [],
                yData: [],
                xData:[],
                xData2:[],
                pieData: [],
                visible: false,
                time: null,
                allData: null,
                start: 0,
                end: 100,
                type: 0,
                startTime: '',
                endTime: '',
                lineData: [],
                goodsName: '',
                goodsId: null,
                startTime1: '',
                endTime1: '',
                showPopover: false,
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },

            }
        },
        mounted() {
            this.getTodayTime()
            this.getChart(this.time)
            bus.$on('clickBar',param =>{
                this.time = param.name
                this.getChart(param.name)
            })
            //bus.$off('refresh')
            bus.$on('refresh',params =>{
                this.startTime = params.startTime
                this.endTime = params.endTime
                this.getChart(this.startTime ? '' : this.time)
            })
            if (localStorage.getItem("time5_5")) {
                let time1 = JSON.parse(localStorage.getItem('time5_5'))
                this.startTime1 = time1.startTime
                this.endTime1 = time1.endTime
            }
        },

        methods: {
            timeConfirm(type){
                if(type === 1){
                    this.startTime1 = formatTime(this.value[0],'yyyy-MM-dd HH:mm:ss')
                    this.endTime1 = formatTime(this.value[1],'yyyy-MM-dd HH:mm:ss')
                }else{
                    this.startTime1 = ''
                    this.endTime1 = ''
                    this.value = ['','']
                }
                this.getLineCharts()
                // this.$refs.blChart.parentHandleclick({startTime: this.startTime,endTime: this.endTime});
                this.showPopover = false
            },
            changeType(type){
                this.type = type
                if(type === 0){
                    this.xData2 = []
                    this.yData = this.allData.incomeGoods.map(m => m.goodsName)
                    if(this.yData.length >= 10){
                        this.start = 100 - (10 / this.yData.length * 100)
                    }else{
                        this.start = 0
                    }
                    let index = 0
                    this.xData = this.allData.incomeGoods.map(m => {
                        return {
                            value: m.money.toFixed(2),
                            itemStyle: itemStyleList[index++]
                        }
                    })
                    index = 0
                    this.pieData = this.allData.incomeGoods.map(m => {
                        return {
                            value: m.money.toFixed(2),
                            name: m.goodsName,
                            itemStyle: itemStyleList[index++]
                        }
                    })

                    this.getEchart();
                }else{
                    this.yData = this.allData.consumptionGoods.map(m => m.goodsName)
                    if(this.yData.length >= 10){
                        this.start = 100 - (10 / this.yData.length * 100)
                    }else{
                        this.start = 0
                    }
                    let index = 0
                    this.xData = this.allData.consumptionGoods.map(m => {
                        return {
                            value: m.money.toFixed(2),
                            itemStyle: itemStyleList[index++]
                        }
                    })
                    index = 0
                    this.xData2 = this.allData.consumptionGoods.map(m =>{
                        return {
                            value: m.breakageMoney.toFixed(2),
                            itemStyle: {
                                color: '#ed1941'
                            }
                        }
                    })
                    this.pieData = this.allData.consumptionGoods.map(m => {
                        return {
                            value: m.money.toFixed(2),
                            name: m.goodsName,
                            itemStyle: itemStyleList[index++]
                        }
                    })
                    this.getEchart();
                }
            },
            getTodayTime(){
                let nowDate = new Date()
                this.time = nowDate.getFullYear() + '-' + ((nowDate.getMonth() + 1) < 10 ? '0' +　(nowDate.getMonth() + 1) :　(nowDate.getMonth() + 1)) + '-' + (nowDate.getDate() < 10 ? '0' + nowDate.getDate() :nowDate.getDate())

            },
            getChart(time){
                api.get('/v1/wx/companyScreen/chartTwo4',{companyId: localStorage.getItem('company_id'),time: time,type: localStorage.getItem('type_1'),startTime: this.startTime,endTime: this.endTime}).then(res =>{
                    if(res.code === 200){
                        this.allData = res.data
                        if(this.type === 0){
                            this.xData2 = []
                            this.yData = res.data.incomeGoods.map(m => m.goodsName)
                            if(this.yData.length >= 10){
                                this.start = 100 - 10 / this.yData.length * 100
                            }
                            let index = 0
                            this.xData = res.data.incomeGoods.map(m => {
                                return {
                                    value: m.money.toFixed(2),
                                    itemStyle: itemStyleList[index++]
                                }
                            })
                            index = 0
                            this.pieData = this.allData.incomeGoods.map(m => {
                                return {
                                    value: m.money.toFixed(2),
                                    name: m.goodsName,
                                    itemStyle: itemStyleList[index++]
                                }
                            })
                            this.getEchart();
                        }else{
                            this.yData = this.allData.consumptionGoods.map(m => m.goodsName)
                            if(this.yData.length >= 10){
                                this.start = 100 - 10 / this.yData.length * 100
                            }
                            let index = 0
                            this.xData = this.allData.consumptionGoods.map(m => {
                                return {
                                    value: m.money.toFixed(2),
                                    itemStyle: itemStyleList[index++]
                                }
                            })
                            index = 0
                            this.xData2 = this.allData.consumptionGoods.map(m =>{
                                return {
                                    value: m.breakageMoney.toFixed(2),
                                    itemStyle: {
                                        color: '#ed1941'
                                    }
                                }
                            })
                            this.pieData = this.allData.consumptionGoods.map(m => {
                                return {
                                    value: m.money.toFixed(2),
                                    name: m.goodsName,
                                    itemStyle: itemStyleList[index++]
                                }
                            })
                            this.getEchart();
                        }

                        // this.list = res.data
                        // this.yData = res.data.map(m => m.goodsName)
                        // let index = 0
                        // this.xData = res.data.map(m => {
                        //   return {
                        //     value: m.money,
                        //     itemStyle: itemStyleList[index++]
                        //   }
                        // })
                        // this.getEchart();
                    }
                })
            },
            // dataFormatter(obj) {
            //   let pList = this.yData;
            //   let temp;
            //   for (let y = 2016; y <= 2020; y++) {
            //     let max = 0;
            //     let sum = 0;
            //     temp = obj[y];
            //     for (let i = 0, l = temp.length; i < l; i++) {
            //       max = Math.max(max, temp[i]);
            //       sum += temp[i];
            //       obj[y][i] = {
            //         name: pList[i],
            //         value: temp[i]
            //       };
            //     }
            //     obj[y + 'max'] = Math.floor(max / 100) * 100;
            //     obj[y + 'sum'] = sum;
            //   }
            //   return obj;
            // },
            getLineCharts(){

                api.get('/v1/wx/companyScreen/popupChart1',{goodsId: this.goodsId,companyId:localStorage.getItem('company_id'),startTime: this.startTime1,endTime: this.endTime1}).then(res =>{
                    if(res.code === 200){
                        this.showLine = true
                        this.lineData = res.data
                        this.$nextTick(() =>{
                            let chartDom = document.getElementById('chart_line');
                            let lineChart = echarts.init(chartDom);
                            let option
                            lineChart.setOption(
                                (option = {
                                    title: {
                                        text: this.goodsName + '销售情况',
                                        left: 'center',
                                        textStyle: {
                                            color: '#ffffff',
                                        }
                                    },
                                    tooltip: {
                                        trigger: 'axis'
                                    },
                                    grid: {
                                        left: '5%',
                                        right: '5%',
                                        bottom: '10%'
                                    },
                                    xAxis: [{
                                        // nameTextStyle: {
                                        //     color: '#fff',
                                        //     fontSize: 14
                                        // },
                                        // lineStyle: {
                                        //     color: '#fff',
                                        // },
                                        data: this.lineData.map(m => {
                                            return m.time;
                                        }),
                                        axisLine: {
                                            lineStyle: {
                                                color: '#ffffff',
                                                // width: 1, //这里是为了突出显示加上的
                                            }
                                        },
                                        axisLabel: {
                                            fontSize: 14
                                        }

                                    }],
                                    yAxis: {
                                        name: '(件)',
                                        nameTextStyle: {
                                            color: '#fff',
                                            fontSize: 14
                                        },
                                        lineStyle: {
                                            color: '#fff',
                                        },
                                        axisLine: {
                                            lineStyle: {
                                                color: '#ffffff',
                                                width: 1, //这里是为了突出显示加上的
                                            }
                                        },
                                        axisLabel: {
                                            fontSize: 14
                                        }
                                    },
                                    dataZoom: [
                                        {
                                            type: 'inside',
                                            start: this.lineData.length >= 80 ? (100 - (80 / this.lineData.length * 100)) : 0,
                                            end: 100
                                        }
                                    ],
                                    visualMap: {
                                        show: false,
                                        top: 50,
                                        right: 10,
                                        pieces: [
                                            {
                                                gt: 0,
                                                lte: 5,
                                                color: '#93CE07'
                                            },
                                            {
                                                gt: 5,
                                                lte: 10,
                                                color: '#FBDB0F'
                                            },
                                            {
                                                gt: 10,
                                                lte: 15,
                                                color: '#FC7D02'
                                            },
                                            {
                                                gt: 15,
                                                lte: 20,
                                                color: '#FD0100'
                                            },
                                            {
                                                gt: 20,
                                                lte: 30,
                                                color: '#AA069F'
                                            },
                                            {
                                                gt: 30,
                                                color: '#AC3B2A'
                                            }
                                        ],
                                        outOfRange: {
                                            color: '#999'
                                        }
                                    },
                                    series: {
                                        name: this.goodsName,
                                        type: 'line',
                                        data: this.lineData.map(m => {
                                            return m.count;
                                        }),
                                        lineStyle:{
                                            width: 3
                                        }
                                    }
                                })
                            );
                        })

                    }
                    console.log(res)
                })

            },
            getEchart() {
                let myChart = echarts.init(document.getElementById('chart_bp'));
                myChart.off('click')
                myChart.on('click',params => {
                    if(this.type === 0){
                        this.goodsId = this.allData.incomeGoods[params.dataIndex].goodsId
                        this.goodsName = this.allData.incomeGoods[params.dataIndex].goodsName
                        if (localStorage.getItem("time5_5")) {
                            let time1 = JSON.parse(localStorage.getItem('time5_5'))
                            this.startTime1 = time1.startTime
                            this.endTime1 = time1.endTime
                        }
                        this.getLineCharts()
                    }
                })
                this.option = {
                    baseOption: {
                        tooltip: {
                            // trigger: 'item',
                            // formatter: params =>{
                            //   params.value += '元'
                            //     return params.value
                            // }
                            trigger: "axis",
                            // backgroundColor: "#009ad6",
                            axisPointer: {
                                type: "shadow",
                                label: {
                                    show: true,
                                    backgroundColor: "#7B7DDC",
                                }
                            },
                            textStyle: { // 提示框浮层的文本样式。
                                color: '#fff',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontFamily: 'sans-serif',
                                fontSize: 16,
                            },

                        },
                        // grid: {
                        //   bottom: '20%',
                        //   right: "42%"
                        // },
                        calculable: true,
                        grid: {
                            left: '20',
                            right: '30%',
                            bottom: '0',
                            top: '40',
                            containLabel: true
                        },
                        dataZoom: [
                            {
                                orient: 'vertical',
                                type: "inside",
                                show: true,
                                start: this.start,
                                end: this.end,
                                // xAxisIndex: [0],
                            }
                        ],
                        yAxis: [{
                            type: 'category',
                            data: this.yData,
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#B4B4B4',
                                }
                            },
                            axisLabel: {
                                color: '#fff',
                                interval:0,
                                // rotate: -10,
                                formatter:function(value)
                                {
                                    // debugger
                                    let ret = "";//拼接加\n返回的类目项
                                    let maxLength = 5;//每项显示文字个数
                                    let valLength = value.length;//X轴类目项的文字个数
                                    let rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                                    if (rowN > 1)//如果类目项的文字大于3,
                                    {
                                        for (let i = 0; i < 2; i++) {
                                            let temp = "";//每次截取的字符串
                                            let start = i * maxLength;//开始截取的位置
                                            let end = start + maxLength;//结束截取的位置
                                            //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                                            temp = value.substring(start, end) + "\n";
                                            ret += temp; //凭借最终的字符串
                                        }
                                        return ret;
                                    }
                                    else {
                                        return value;
                                    }
                                }
                            },
                        }],
                        xAxis: [{
                            type: 'value',
                            name: '(元)',
                            nameTextStyle:{
                                color: '#fff',
                                fontSize: 14
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#B4B4B4'
                                }
                            },

                        }],
                        series: [{
                            name: this.type === 0 ? '收益' : '成本',
                            type: 'bar',
                            barMaxWidth: 15,
                            center: ['20%', '65%'],
                            seriesLayoutBy: 'row',
                            // markPoint: {
                            //   symbol: 'pin',
                            //   itemStyle: {
                            //     normal: {
                            //       color: '#eb9b44',
                            //       shadowColor: '#eb9b44',
                            //       shadowBlur: 15
                            //     }
                            //   },
                            //   data: this.xData,
                            // },
                            data: this.xData,
                            label: {
                                show: true,
                                position: 'right',
                                // formatter:function(params){
                                //     return  params.value + '元'
                                // }
                            },
                        },
                            {
                                name: "报损",
                                type: "bar",
                                barWidth: 12,
                                itemStyle: {
                                    normal: {
                                        //barBorderRadius: 10,
                                        color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            { offset: 0, color: "#956FD4" },
                                            { offset: 1, color: "#3EACE5" }
                                        ])
                                    }
                                },
                                data: this.xData2,
                                label: {
                                    show: true,
                                    position: 'right',
                                    formatter: function(params) {
                                        const a = params.value
                                        if (a > 0) {
                                            return a
                                        } else {
                                            return ''
                                        }
                                    }
                                },
                            },
                            {
                                name: '占比',
                                type: 'pie',
                                center: ['82%', '50%'],
                                radius: '45%',
                                z: 100,
                                data: this.pieData.slice((this.pieData.length - 9) < 0 ? 0 : this.pieData.length - 8,this.pieData.length),
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                                    }
                                }
                            }

                        ]
                    },
                    options: [{
                        series: [
                            {
                                data: this.xData,
                            }]
                    }]
                }

                myChart.setOption(this.option, true);

                window.addEventListener('resize', () => {
                    myChart.resize();
                });


                // let lastIndex = 0;
                // let currentIndex = 0;
                // setInterval(() => {
                //     myChart.dispatchAction({
                //         type: 'downplay',
                //         seriesIndex: 0,
                //         dataIndex: lastIndex,
                //     });
                //     myChart.dispatchAction({
                //         type: 'highlight',
                //         seriesIndex: 0,
                //         dataIndex: currentIndex,
                //     });
                //     myChart.dispatchAction({
                //         type: 'showTip',
                //         seriesIndex: 0,
                //         dataIndex: currentIndex,
                //     });
                //     lastIndex = currentIndex;
                //     ++currentIndex;
                //     console.log('currentIndex',currentIndex)
                //     console.log('this.xData.length + this.pieData.length',this.xData.length)
                //     if(currentIndex > (this.xData.length)) {
                //         currentIndex = 0;
                //     }
                // }, 3000)

            }
        },
        beforeDestroy() {

        }
    };
</script>

<style lang="scss" scoped>
    .sn-container {
        padding-top: 16px;
        left: 50px;
        top: 1548px;
        width: 100%;
        height: 550px;
        .chartsdom {
            width: 100%;
            height: 100%;
        }
        .sn-body1 {
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            bottom: 0;
            height: 500px!important;
            .wrap-container{
                height: 100%;
                width: 100%;
            }
        }
    }
    .c-head{
        position: absolute;
        top: 30px;
        right: 0;
        display: flex;
    }
    .df{
        display: flex;
        justify-content: end;
        padding-right: 40px;
    }
    .df1{
        display: flex;
        justify-content: end;
        padding-right: 10px;
    }

    .box-btn{
        height: 20px;
        width: 40px;
        border-radius: 5px;
        line-height: 20px;
        cursor: pointer;
        margin-right: 5px;
        z-index: 100;
    }
    .bgc-956{
        background: linear-gradient(to bottom,#956FD4,#3EACE5);
    }
    .bgc-3EA{
        background: linear-gradient(to bottom,rgba(156,107,211,0.8),rgba(156,107,211,0.2));
    }
    .record-bg{
        background-image: url("../../assets/image/cool-background.png");
        overflow: hidden;
        border-radius: 20px;
        background-size: cover;
        z-index: 100;
        /*background-repeat: no-repeat;*/
    }
    .box-i-time1{
        cursor: pointer!important;
        width: 50px;
        height: 50px;
        position: absolute;
        font-size: 30px;
        left: 30px;
        top:2%;
        z-index: 100;
    }
</style>
