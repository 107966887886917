<template>
  <div>
    <Echart
      :options="options"
      id="bottomLeftChart"
      height="480px"
      width="100%"
      @getParams="getParams"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart'
import bus from '@/util/bus'
import Vue from "vue";
  const colorList = [
    [
      "#33a3dc",
      "#7bbfea",
      "#90d7ec"
    ],[
      "#007947",
      "#73b9a2",
      "#72baa7"
    ],[
      "#6950a1",
      "#6f60aa",
      "#9b95c9"
    ],
  ]
export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler (newData) {
        this.options = {
          title: {
            text: "",
          },
          dataZoom: [
            {
              type: "inside",
              show: true,
              start: 0,
              end: this.cdata.end,
              xAxisIndex: [0],
            }
          ],
          // tooltip: {
          //   trigger: "axis",
          //   backgroundColor: "rgba(255,255,255,0.1)",
          //   axisPointer: {
          //     type: "shadow",
          //     label: {
          //       show: true,
          //       backgroundColor: "#7B7DDC",
          //     }
          //   },
          //   textStyle: { // 提示框浮层的文本样式。
          //     color: '#fff',
          //     fontStyle: 'normal',
          //     fontWeight: 'normal',
          //     fontFamily: 'sans-serif',
          //     fontSize: 16,
          //   },
          // },
          legend: {
            data: ['次数'],
            textStyle: {
              color: "#B4B4B4",
              fontSize: '16'
            },
            top: "0%",
            right: '5%',
            itemWidth: 40, // 图例标记的图形宽度。
            itemGap: 20, // 图例每项之间的间隔。
            itemHeight: 20, //  图例标记的图形高度。
          },
          grid: {
            x: "5%",
            width: "92%",
            y: "20%"
          },
          xAxis: {
            data: newData.category,
            axisLine: {
              lineStyle: {
                color: "#B4B4B4",
              }
            },
            axisTick: {
              show: false
            },
            // inverse:true
          },
          yAxis: [
            {
              name: '(次)',
              nameGap: 5,
              minInterval: 1,
              nameTextStyle:{
                color: '#fff',
                fontSize: 14
              },
              splitLine: { show: false },
              axisLine: {
                lineStyle: {
                  color: "#B4B4B4"
                }
              },
              axisLabel: {
                formatter: "{value} "
              }
            }
          ],
          // series: [
          //
          //   {
          //     name: "次数",
          //     type: "bar",
          //     barGap: "-100%",
          //     barWidth: 50,
          //     itemStyle: {
          //       normal: {
          //         barBorderRadius: 0,
          //         color: function (params) {
          //           let _this = this
          //           let index = params.dataIndex;
          //           index = index % 3
          //           return new Vue.prototype.$echarts.graphic.LinearGradient(1, 1, 0, 0, [{
          //             offset: 0,
          //             color: colorList[index][0]
          //           },
          //             {
          //               offset: 0.5,
          //               color: colorList[index][1]
          //             },{
          //               offset: 1,
          //               color: colorList[index][2]
          //             }
          //           ]);
          //         }
          //       }
          //     },
          //     z: -12,
          //     data: newData.lineData,
          //     markPoint: {
          //       data: [
          //         { type: 'max', name: 'Max' },
          //         { type: 'min', name: 'Min' },
          //         { type: 'average', name: 'average' }
          //       ]
          //     },
          //   },
          //
          // ],
          series: [
            {
              //三个最低下的圆片
              // 系列名称，用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列
              name: "",
              type: "pictorialBar",
              symbolSize: [40, 8],
              symbolOffset: [0, 5],
              z: 12,
              // 图形样式
              itemStyle: {
                opacity: 1,
                normal: {
                  show: true,
                  color:function(params){
                    // var colorlist = [new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#09A4F8",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#36B8FB",
                    //   },
                    // ]),
                    //   new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //     {
                    //       offset: 0,
                    //       color: "#0F9DE8",
                    //     },
                    //     {
                    //       offset: 1,
                    //       color: "#5ABBEF",
                    //     },
                    //   ])];
                    let colorList = []
                    // newData.lineData.forEach(item =>{
                    //   colorList.push(new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //     {
                    //       offset: 0,
                    //       color: "#09A4F8",
                    //     },
                    //     {
                    //       offset: 1,
                    //       color: "#36B8FB",
                    //     },
                    //   ]))
                    // })
                    return colorList[params.dataIndex];
                  },
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  //     {
                  //     offset: 0,
                  //     color: "#36B8FB",//0%处的颜色
                  //     },
                  //     {
                  //     offset: 1,
                  //     color: "#09A4F8",//100%处的颜色
                  //     },
                  // ]),
                  barBorderRadius: 0,
                  borderWidth: 0,
                },
              },
              // 显示最底下的圆片
              data: newData.lineData,
            },

            //下半截柱状图
            {
              name: "",
              type: "bar",
              barWidth: 40,
              barGap: "100%",
              itemStyle: {
                //lenged文本
                opacity: 0.1,
                normal: {
                  show: true,
                  color:function(params){
                    // var colorlist = [new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#07A2F9",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#53EEF0",
                    //   },
                    // ]),
                    //   new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //     {
                    //       offset: 0,
                    //       color: "#00A7F7",
                    //     },
                    //     {
                    //       offset: 1,
                    //       color: "#B26BE9",
                    //     },
                    //   ])];
                    let colorList = []
                    newData.lineData.forEach(item =>{
                      colorList.push(new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        {
                          offset: 0,
                          color: "#07A2F9",
                        },
                        {
                          offset: 1,
                          color: "#53EEF0",
                        },
                      ]))
                    })
                    return colorList[params.dataIndex];
                  },
                  barBorderRadius: 0,
                  borderWidth: 0,
                },
              },
              label: {
                show: true,
                position: "top",
                distance: 10,
                color: "#fff",
              },
              data: newData.lineData,
            },
            {
              name: "",
              type: "pictorialBar",
              // 图形的大小
              symbolSize: [40, 8],
              // 图形相对于原本位置的偏移
              symbolOffset: [0, -5],
              z: 12,
              itemStyle: {
                opacity: 1,
                normal: {
                  show: true,
                  color:function(params){
                    // var colorlist =  [new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //   {
                    //     offset: 0,
                    //     color: "#06949F",
                    //   },
                    //   {
                    //     offset: 1,
                    //     color: "#2BB9C1",
                    //   },
                    // ]),
                    //   new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    //     {
                    //       offset: 0,
                    //       color: "#0F9DE8",
                    //     },
                    //     {
                    //       offset: 1,
                    //       color: "#5ABBEF",
                    //     },
                    //   ])];
                    let colorList = []
                    newData.lineData.forEach(item =>{
                      colorList.push(new Vue.prototype.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        {
                          offset: 0,
                          color: "#06949F",
                        },
                        {
                          offset: 1,
                          color: "#2BB9C1",
                        },
                      ]))
                    })
                    return colorList[params.dataIndex];
                  },
                  barBorderRadius: 0,
                  borderWidth: 0,
                },
              },
              // 图形的定位位置
              symbolPosition: "end",
              data: newData.lineData,
            },
          ],
        }
      },
      immediate: true,
      deep: true
    },

  },
  mounted() {
  },
  methods:{
    getParams(params){
      console.log(params)
      let data = {
        dataIndex: params.dataIndex,
      }
      bus.$emit('clickHaccpBar',data)
    }
  },
}
</script>
