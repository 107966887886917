<template>
    <div id="threeChart" ref="threeChart">
        <!--      <div v-for="(item,index) in data" :key="index" class="three-label active" :style="{top: (halfHeight - (item.height * 2.6)) + 'px',left: (halfWidth + item.x * 4)  + 'px'}">{{item.num}}</div>-->
    </div>
</template>

<script>
    import remoteLoad from '../../util/remoteLoad'
    // 需要用官方版本的tween.js

    // let camera, scene, renderer;
    // let cubeGroup,labelNameGroup = [],  labelGroup= [];
    // let stats;
    export default {

        name: 'threeDChart',
        props: {
            list:{
                type: Array,
            }
        },
        data () {
            return {
                camera: null,
                scene: null,
                renderer: null,
                cubeGroup: [],
                labelGroup: [],
                labelNameGroup: [],
                stats: null,
                data : [],
                halfWidth: 0,
                halfHeight: 0,
                threeChart: null
            }
        },
        watch:{
          list:{
              handler(v){
                  this.data = v.map((item,index) =>{
                      return {
                          width: 5,
                          height: item.count * 5,
                          x: index <= this.list.length / 2 ? (0 - this.list.length / 2 + index) * 50 : (index - this.list.length / 2) * 50,
                          num: item.count,
                          name: item.userName
                      }
                  })
                  this.init()
              }
          }
        },
        async mounted() {
            await remoteLoad('https://hafly.github.io/resources/CodePen/three/r102/three.min.js')
            await remoteLoad('https://hafly.github.io/resources/CodePen/three/libs/tween.js')
            await remoteLoad('https://hafly.github.io/resources/CodePen/three/libs/stats.min.js')
            this.data = this.list.map((item,index) =>{
                return {
                    width: 5,
                    height: item.count * 5,
                    x: index <= this.list.length / 2 ? (0 - this.list.length / 2 + index) * 50 : (index - this.list.length / 2) * 50,
                    num: item.count,
                    name: item.userName
                }
            })
            this.threeChart = this.$refs.threeChart
            this.init()
            this.update()
        },
        methods:{
            init() {
                // this.scene
                this.scene = new THREE.Scene();
                // this.camera
                let frustumSize = 150;
                let aspect = threeChart.offsetWidth / threeChart.offsetHeight;
                this.camera = new THREE.OrthographicCamera(frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 1, 1000);
                this.camera.position.set(0, 150, 200);
                this.camera.lookAt(new THREE.Vector3(0, 0, -200));

                // this.renderer
                this.renderer = new THREE.WebGLRenderer();
                this.renderer.setSize(this.threeChart.offsetWidth, this.threeChart.offsetHeight);
                this.renderer.setPixelRatio(window.devicePixelRatio);
                this.threeChart.appendChild(this.renderer.domElement);

                this.renderer.render(this.scene, this.camera);

                let material = new THREE.SpriteMaterial({
                    map: new THREE.CanvasTexture(this.generateSprite()),
                    blending: THREE.AdditiveBlending,
                    transparent: true,
                    opacity: 1
                });

                this.cubeGroup = new THREE.Group();
                this.scene.add(this.cubeGroup);
                this.data.forEach((params, index) => {
                    this.initCube(params, material, index);
                });
                this.updateLabel();

                this.stats = new Stats();
                this.threeChart.appendChild(this.stats.dom);
                this.onWindowResize()
                window.addEventListener('resize', this.onWindowResize, false);
            },

            update() {
                requestAnimationFrame(this.update);
                TWEEN.update(undefined, true);
                this.renderer.render(this.scene, this.camera);
                this.stats.update();
            },

            initCube(params, material, index) {
                let cube = new THREE.Group();

                let width = params.width; // 立方体宽
                let height = params.height; // 立方体高
                let space = 0.5; // 立方体面粒子间距


                let targetPos = [];
                // 粒子化立方体6个面
                for (let y = 0; y <= height; y += space) {
                    for (let x = -width; x <= width; x += space * 2) {
                        for (let z = -width; z <= width; z += space * 2) {
                            if (y === 0 || y === height) {
                                let particle = new THREE.Sprite(material);
                                particle.position.set(x, 0, z);
                                particle.scale.set(0.8, 0.8);
                                cube.add(particle);
                                targetPos.push(y);
                            } else if (((x === -width && z === -width) || (x === -width && z === width) || (x === width && z === -width) || (x === width && z === width))) {
                                let particle = new THREE.Sprite(material);
                                particle.position.set(x, 0, z);
                                cube.add(particle);
                                targetPos.push(y);
                            }
                        }
                    }
                }

                cube.position.x = params.x;
                this.cubeGroup.add(cube);

                this.initLabel(params, cube);
                this.initNameLabel(params, cube);

                // 初始动画
                for (let i = 0; i < cube.children.length; i++) {
                    new TWEEN.Tween(cube.children[i].position)
                        .delay(index * 200)
                        .to({
                            y: targetPos[i]
                        }, 2000)
                        .easing(TWEEN.Easing.Quadratic.Out)
                        .start();
                }

                let tween = new TWEEN.Tween(cube.rotation)
                    .delay(index * 200)
                    .to({
                        y: THREE.Math.DEG2RAD * (720 + 30)
                    }, 2000)
                    .easing(TWEEN.Easing.Quadratic.Out)
                    .onComplete(() => {
                        tween.stop();
                        this.labelGroup[index].className = 'three-label active';
                        this.labelNameGroup[index].className = 'three-label active';
                        // 立方体中间粒子
                        this.initParticles(cube, width, height, material);
                    })
                    .start();
            },

            // 标签位置
            initLabel(params) {
                let ele = document.createElement('div');
                ele.className = 'three-label';
                ele.innerHTML = this.commafy(params.num, 0);
                this.threeChart.appendChild(ele);
                this.labelGroup.push(ele);
            },
            initNameLabel(params) {
                let ele = document.createElement('div');
                ele.className = 'three-label';
                ele.innerHTML = params.name;
                this.threeChart.appendChild(ele);
                this.labelNameGroup.push(ele);
            },
            updateLabel() {
                this.labelGroup.forEach((ele, index) => {
                    let params = this.data[index];
                    let halfWidth = this.threeChart.offsetWidth / 2;
                    let halfHeight = this.threeChart.offsetHeight / 2;
                    let pos = new THREE.Vector3(params.x, params.height + 10, 0);

                    let vector = pos.project(this.camera); // threejs 世界坐标转屏幕坐标
                    let l = (1 + vector.x) * halfWidth;
                    let t = (1 - vector.y) * halfHeight;

                    // ele.style.left = halfWidth + params.x + 'px';
                    ele.style.left = (l - params.width / 2) - ele.offsetWidth / 2 + 'px';
                    ele.style.top = t + 'px';
                })
                this.labelNameGroup.forEach((ele, index) => {
                    let params = this.data[index];
                    let halfWidth = this.threeChart.offsetWidth / 2;
                    let halfHeight = this.threeChart.offsetHeight / 2;
                    let pos = new THREE.Vector3(params.x, params.height + 10, 0);

                    let vector = pos.project(this.camera); // threejs 世界坐标转屏幕坐标
                    //console.log('vector',vector)
                    let l = (1 + vector.x) * halfWidth;
                    let t = (1 - vector.y) * halfHeight;

                    // ele.style.left = halfWidth + params.x + 'px';
                    ele.style.left = (l - params.width / 2) - ele.offsetWidth / 2 + 'px';
                    ele.style.top = 650 + 'px';
                })
                //console.log('this.labelGroup',this.labelGroup)
            },

            // 初始化立方体中间粒子
            initParticles(cube, width, height, material) {
                let particles = [];
                let flag = true;
                let timer = setInterval(() => {
                    // 不断生成粒子
                    if (!flag) clearInterval(timer);
                    // 每次发射粒子数量
                    for (let i = 0; i < Math.floor(height / 4); i++) {
                        let _material = material.clone();
                        _material.opacity = THREE.Math.randFloat(0.5, 1);
                        let particle = new THREE.Sprite(_material);
                        particle.position.set(THREE.Math.randFloat(-width, width), THREE.Math.randFloat(0, 5), THREE.Math.randFloat(-width, width));
                        particle.scale.set(Math.random() + 0.5, Math.random() + 0.5, 1);
                        particles.push(particle);
                        cube.add(particle);

                        let tween = new TWEEN.Tween(particle.position)
                            .delay(0)
                            .to({
                                y: height - THREE.Math.randFloat(5, 30)
                            }, 2500)
                            .easing(TWEEN.Easing.Linear.None)
                            .onComplete(() => {
                                flag = false;
                                particle.position.set(THREE.Math.randFloat(-width, width), 0, THREE.Math.randFloat(-width, width));
                            })
                            .start();
                        tween.chain(tween);
                    }
                }, 100);
            },

            // 创建CanvasTexture
            generateSprite() {
                let canvas = document.createElement('canvas');
                canvas.width = 16;
                canvas.height = 16;

                let context = canvas.getContext('2d');
                let gradient = context.createRadialGradient(canvas.width / 2, canvas.height / 2, 0, canvas.width / 2, canvas.height / 2, canvas.width / 2);
                // let gradient = context.createRadialGradient(5, 5, 0, 5, 5, 5);
                gradient.addColorStop(0, 'rgba(255,255,255,1)');
                gradient.addColorStop(0.2, 'rgba(5, 172, 217,1)');
                gradient.addColorStop(0.4, 'rgba(5, 94, 130,1)');
                gradient.addColorStop(1, 'rgba(0,0,0,1)');

                context.fillStyle = gradient;
                context.fillRect(0, 0, canvas.width, canvas.height);
                return canvas;
            },
            onWindowResize() {
                let frustumSize = 200;
                let aspect = this.threeChart.offsetWidth / this.threeChart.offsetHeight;
                this.camera.left = frustumSize * aspect / -2;
                this.camera.right = frustumSize * aspect / 2;
                this.camera.top = frustumSize / 2;
                this.camera.bottom = frustumSize / -2;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(this.threeChart.offsetWidth, this.threeChart.offsetHeight);
                this.updateLabel();
            },

            commafy(num, fix) {
                try {
                    if (num != null) {
                        if (fix || fix == 0) {
                            num = parseFloat(num).toFixed(fix) + "";
                        } else {
                            num += "";
                        }
                        let re = /(-?\d+)(\d{3})/;
                        while (re.test(num)) {
                            num = num.replace(re, "$1,$2");
                        }
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return num;
                }
            }


        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
    #threeChart {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .three-label {
        position: absolute;
        color: #ffffff!important;
        opacity: 0;
        transition: opacity 1s;
        text-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px rgb(255, 0, 222), 0 0 40px rgb(255, 0, 222), 0 0 70px rgb(255, 0, 222), 0 0 80px rgb(255, 0, 222), 0 0 100px rgb(255, 0, 222);
    }
    .three-label.active {
        opacity: 1;
    }
</style>
