// initial state
const state = {
    samplePage: {
      pager_offset: '0',
        pager_openset:'10',
        sample_time:'',
        sample_name:''
    },
  }
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    setNewPage ({ commit }, newValue) {
      commit('setNewPage', newValue)
    },
  }
  
  // mutations
  const mutations = {
    setNewPage (state, newValue) {
      state.samplePage.pager_offset= newValue.pager_offset
      state.samplePage.sample_name= newValue.sample_name
      state.samplePage.sample_time= newValue.sample_time==null?'':newValue.sample_time
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  