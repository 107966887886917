// initial state
const state = {
    goodsState: {
        pager_offset: '0',
        pager_openset: '10',
        name: "",
        goods_code: "",
    },
};
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    setNewPage ({ commit }, newValue) {
      commit('setNewPage', newValue)
    },
  }
  
  // mutations
  const mutations = {
    setNewPage (state, newValue) {
      state.goodsState.pager_offset= newValue.pager_offset
      state.goodsState.name= newValue.name
      state.goodsState.goods_code= newValue.goods_code
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  