
<template>
    <div class="wrap-container sn-container">
        <div class="sn-content">
            <!--      <div class="sn-title1">近半年月度利润</div>-->
            <div class='box-title'>
                <span  class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">近半年月度利润</dv-decoration-11></span>
            </div>
            <div class="sn-body1">
                <div class="wrap-container">
                    <dv-loading class="loading" v-show="loading"></dv-loading>
                    <div class="chartsdom" ref="myCharts"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../util/extra-api'
    var echarts = require('echarts/lib/echarts');
    // 引入柱状图
    require('echarts/lib/chart/bar');
    require('echarts/lib/chart/pie');
    export default {
        name: "pyramidTrend",
        props: {
            screenType: {
                type: Number,
            }
        },
        data() {
            return {
                option: null,
                dataMap: {},
                chart2Data: null,
                loading: true
            }
        },
        mounted() {
            this.getChart2()
        },
        methods: {
            getChart2(){
                if(this.screenType === 0){
                    api.get('/v1/wx/companyScreen/chart2',{companyId: localStorage.getItem('company_id'),type: localStorage.getItem('type')}).then(res =>{
                        if(res.code === 200){
                            this.chart2Data = res.data
                            this.getEchart();
                            this.loading = false
                        }
                    })
                }else{
                    api.get('/v1/wx/companyScreen/chartTwo2',{companyId: localStorage.getItem('company_id'),type: localStorage.getItem('type')}).then(res =>{
                        if(res.code === 200){
                            this.chart2Data = res.data
                            this.getEchart();
                            this.loading = false
                        }
                    })
                }
            },
            // dataFormatter(obj) {
            //   let temp = this.chart2Data.
            //   // for (let x = 0; x < 3; x++) {
            //   //     let max = 0;
            //   //     let sum = 0;
            //   //     temp = obj[x];
            //   //     for (let i = 0, l = temp.length; i < l; i++) {
            //   //         max = Math.max(max, temp[i]);
            //   //         sum += temp[i];
            //   //         obj[x][i] = {
            //   //           name: pList[i],
            //   //           value: temp[i]
            //   //         };
            //   //     }
            //   //     obj[x + 'max'] = Math.floor(max / 100) * 100;
            //   //     obj[x + 'sum'] = sum;
            //   // }
            //   return obj;
            // },
            getEchart() {
                let xData = this.chart2Data.map(m => m.month)
                let yData = this.chart2Data.map(m => m.pureProfit.toFixed(2))
                let myChart = echarts.init(this.$refs.myCharts);
                let itemStyle = {
                    barBorderRadius: [15, 0],
                    color: '#0084ff'
                }

                // this.dataMap.dataType = this.dataFormatter({
                //   2:[124,145,261,54,195,131,150,39,11,40,23,51,45,88],
                //   1:[136,159,205,41,306,7,77,101,24,34,8,15,14,9],
                //   0:[118,128,220,47,92,14,9,11,113,61,11,22,33,5],
                // });

                this.option = {
                    baseOption: {
                        timeline: {
                            show: false
                        },
                        calculable: true,
                        grid: {
                            top: '10%',
                            bottom: '25%',
                            left: '17%',
                            right: '15%'
                        },
                        xAxis: [{
                            type: 'value',
                            name: '(元)',
                            splitNumber: 3,
                            nameTextStyle:{
                                color: '#fff',
                                fontSize: 12
                            },
                            splitLine: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: '#B4B4B4'
                                }
                            }
                        }],
                        yAxis: [
                            {
                                type: 'category',
                                axisLabel: {
                                    interval: 0
                                },
                                data: xData,
                                splitLine: {
                                    show: false
                                },
                                axisTick: {
                                    show: false
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#B4B4B4',
                                    }
                                },
                                inverse:true
                            }],
                        series: [{
                            name: '一类',
                            type: 'bar',
                            barWidth: 15,
                            legendHoverLink: true,
                            label: {
                                show: true,
                                position: 'right',
                                color: '#fff',
                                fontSize: 14
                            },
                        }]
                    },
                    options: [{
                        series: {
                            data: yData,
                            itemStyle: itemStyle
                        }
                    }]
                }

                myChart.setOption(this.option, true);

                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            }
        },
        beforeDestroy() {

        }
    };
</script>

<style lang="scss" scoped>
    .sn-container {
        padding-top: 16px;
        left: 666px;
        top: 1548px;
        width: 100%;
        height: 500px;
        .chartsdom {
            width: 100%;
            height: 100%;

        }
        .sn-title1{
            position: absolute;
            height: 26px;
            font-size: 16px;
            color: #00c2ff;
            line-height: 26px;
            padding: 0 0 0 20px;
            left: 10px;
            right: 10px;
            top: 10px;
        }
        .sn-body1 {
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            bottom: 0;
            height: 600px!important;
            .wrap-container{
                height: 100%;
                width: 100%;
            }
        }
    }


</style>
