// initial state
const state = {
    userPage: {
      pager_offset: '0',
        pager_openset:'10',
        user_type:'',
        user_name:'',
        user_state:'',
        query_param:''
    },
  }
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    setNewPage ({ commit }, newValue) {
      commit('setNewPage', newValue)
    },
  }
  
  // mutations
  const mutations = {
    setNewPage (state, newValue) {
      state.userPage.pager_offset= newValue.pager_offset
      state.userPage.user_type= newValue.user_type
      state.userPage.user_name= newValue.user_name
      state.userPage.user_state= newValue.user_state
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  