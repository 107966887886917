// initial state
const state = {
  recordPage: {
    pager_offset: "0",
    expire_state: '1',
    company_name: '',
    filing_state: '3',
    business_state: "",
    business_type: "",
  },
  recordPage1: {
    pager_offset: "0",
    expire_state: '1',
    company_name: '',
    filing_state: '3',
    business_state: "",
    business_type: "",
  },
  recordPage2: {
    pager_offset: "0",
    expire_state: '1',
    company_name: '',
    filing_state: '3',
    business_state: "",
    business_type: "",
  },
  recordPage3: {
    pager_offset: "0",
    expire_state: '1',
    company_name: '',
    filing_state: '3',
    business_state: "",
    business_type: "",
  },
  recordPage4: {
    pager_offset: "0",
    expire_state: '1',
    company_name: '',
    filing_state: '3',
    business_state: "",
    business_type: "",
  },
  recordPage5: {
    pager_offset: "0",
    expire_state: '1',
    company_name: '',
    filing_state: '3',
    business_state: "",
    business_type: "",
  },
  activeName: '3'
}

// getters
const getters = {}

// actions
const actions = {
  setNewPage({
    commit
  }, newValue) {
    commit('setNewPage', newValue)
  },
  setNewPage1({
    commit
  }, newValue) {
    commit('setNewPage1', newValue)
  },
  setNewPage2({
    commit
  }, newValue) {
    commit('setNewPage2', newValue)
  },
  setNewPage3({
    commit
  }, newValue) {
    commit('setNewPage3', newValue)
  },
  setNewPage4({
    commit
  }, newValue) {
    commit('setNewPage4', newValue)
  },
  setNewPage5({
    commit
  }, newValue) {
    commit('setNewPage5', newValue)
  }
}

// mutations
const mutations = {
  setNewPage(state, newValue) {
    state.recordPage.pager_offset = newValue.pager_offset
    state.recordPage.company_name = newValue.company_name
    state.recordPage.expire_state = newValue.expire_state
    state.recordPage.filing_state = newValue.filing_state
    state.recordPage.business_state = newValue.business_state
    state.recordPage.business_type = newValue.business_type
  },
  setNewPage1(state, newValue) {
    state.recordPage1.pager_offset = newValue.pager_offset
    state.recordPage1.company_name = newValue.company_name
    state.recordPage1.expire_state = newValue.expire_state
    state.recordPage1.filing_state = newValue.filing_state
    state.recordPage1.business_state = newValue.business_state
    state.recordPage1.business_type = newValue.business_type
  },
  setNewPage2(state, newValue) {
    state.recordPage2.pager_offset = newValue.pager_offset
    state.recordPage2.company_name = newValue.company_name
    state.recordPage2.expire_state = newValue.expire_state
    state.recordPage2.filing_state = newValue.filing_state
    state.recordPage2.business_state = newValue.business_state
    state.recordPage2.business_type = newValue.business_type
  },
  setNewPage3(state, newValue) {
    state.recordPage3.pager_offset = newValue.pager_offset
    state.recordPage3.company_name = newValue.company_name
    state.recordPage3.expire_state = newValue.expire_state
    state.recordPage3.filing_state = newValue.filing_state
    state.recordPage3.business_state = newValue.business_state
    state.recordPage3.business_type = newValue.business_type
  },
  setNewPage4(state, newValue) {
    state.recordPage4.pager_offset = newValue.pager_offset
    state.recordPage4.company_name = newValue.company_name
    state.recordPage4.expire_state = newValue.expire_state
    state.recordPage4.filing_state = newValue.filing_state
    state.recordPage4.business_state = newValue.business_state
    state.recordPage4.business_type = newValue.business_type
  },
  setNewPage5(state, newValue) {
    state.recordPage5.pager_offset = newValue.pager_offset
    state.recordPage5.company_name = newValue.company_name
    state.recordPage5.expire_state = newValue.expire_state
    state.recordPage5.filing_state = newValue.filing_state
    state.recordPage5.business_state = newValue.business_state
    state.recordPage5.business_type = newValue.business_type
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}