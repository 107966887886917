// initial state
const state = {
  checkSelfPage: {
    pager_offset: '0',
    pager_openset: '10',
    company_name: '',
    is_check_self: '',
    company_id: '',
  },
  checkSelfPage1: {
    pager_offset: '0',
    pager_openset: '10',
    company_name: '',
    is_check_self: '1'
  },
}
// getters
const getters = {}

// actions
const actions = {
  setNewPage({
    commit
  }, newValue) {
    commit('setNewPage', newValue)
  },
  setNewPage1({
    commit
  }, newValue) {
    commit('setNewPage1', newValue)
  },
}

// mutations
const mutations = {
  setNewPage(state, newValue) {
    state.checkSelfPage.pager_offset = newValue.pager_offset
    state.checkSelfPage.is_check_self = newValue.is_check_self
    state.checkSelfPage.company_name = newValue.company_name
    state.checkSelfPage.company_id = newValue.company_id
  },
  setNewPage1(state, newValue) {
    state.checkSelfPage1.pager_offset = newValue.pager_offset
    state.checkSelfPage1.company_name = newValue.company_name
    state.checkSelfPage1.is_check_self = newValue.is_check_self
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}