<template>
  <div >
    <Chart :cdata="cdata" v-if="showChart"/>
  </div>
</template>

<script>
import Chart from './chart.vue'
import bus from '@/util/bus'
export default {
  name: 'kpiChart',
  props: {
    list: {
      type: Array,
    },
    index: {
      type: Number,
    },
    type: {
      type: Number,
    },
  },
  data () {
    return {
      cdata: {
        category: [

        ],
        lineData: [

        ],
        barData: [

        ],
        end: 100,
        index: 0,
      },
      showChart: true
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.list.forEach((item,index) =>{
      this.cdata.category.push(item.userName)
      this.cdata.lineData.push(item.count)
      this.cdata.index = this.index
      if(this.cdata.lineData.length >= 15){
        this.cdata.end = 15 / this.cdata.lineData.length * 100
      }else{
        this.cdata.end = 100
      }
    })
  },
  methods: {
    // 根据自己的业务情况修改

  }
};
</script>

<style lang="scss" scoped>
</style>
