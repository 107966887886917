// initial state
const state = {
    carState: {
        pager_offset: '0',
        pager_openset: '10',
        nature: '',
        car_number: '',
    },
};
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    setNewPage ({ commit }, newValue) {
      commit('setNewPage', newValue)
    },
  }
  
  // mutations
  const mutations = {
    setNewPage (state, newValue) {
      state.carState.pager_offset= newValue.pager_offset
      state.carState.nature= newValue.nature
      state.carState.car_number= newValue.car_number
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  