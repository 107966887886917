import axios from './api'
// 分页
export const onpage = (current) => {
    let pageroffset = 0
    let pageropenset = 10
    pageroffset = (current - 1) * pageropenset
    return pageroffset
  }

  // 存贮
  export const local = {
    get: (value) => {
      let v = localStorage.getItem(value)
      return v ? JSON.parse(v) : null
    },
    set: (key, value) => {
      localStorage.setItem(key, JSON.stringify(value))
    },
    clear: () => {
      localStorage.clear()
    },
    del: (key) => {
      localStorage.removeItem(key)
    }
  }

  // 临时存贮
  export const session = {
    get: (value) => {
      let v = sessionStorage.getItem(value)
      return v ? JSON.parse(v) : null
    },
    set: (key, value) => {
      sessionStorage.setItem(key, JSON.stringify(value))
    },
    clear: () => {
      sessionStorage.clear()
    },
    del: (key) => {
      sessionStorage.removeItem(key)
    }
  }

  export const objKeySort = (obj) => {
    var newkey = Object.keys(obj).sort();
    var newObj = {};
    for (var i = 0; i < newkey.length; i++) {
    newObj[newkey[i]] = obj[newkey[i]];

    }
    return newObj;
  }
  // export const remove=(val){

  // }

  Array.prototype.remove = function(val) {
    var index = this.indexOf(val);
    if (index > -1) {
      this.splice(index, 1);
    }
  };

  export function deepClone(source) {
    if (!source && typeof source !== "object") {
      throw new Error("error arguments", "deepClone");
    }
    const targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach(keys => {
      if (source[keys] && typeof source[keys] === "object") {
        targetObj[keys] = deepClone(source[keys]);
      } else {
        targetObj[keys] = source[keys];
      }
    });
    return targetObj;
  }

  export function diffTime(timeString) {
    if (timeString.length > 8) {
      timeString = timeString.substr(0, 8)
    }
    let beginDate = new Date(timeString.replace(/^(\d{4})(\d{2})(\d{2})$/, "$1/$2/$3")).getTime();
    let endDate = new Date().getTime();
    let diff = endDate - beginDate;
    let days = parseInt(Math.floor(diff / (24 * 3600 * 1000))) + "天";
    return days;
  }

  export function createHash(hashLength) {
    if (!hashLength || typeof Number(hashLength) != "number") {
      return;
    }
    var ar = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '0',
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'i',
        'j',
        'k',
        'l',
        'm',
        'n',
        'o',
        'p',
        'q',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
    ];
    var hs = [];
    var hl = Number(hashLength);
    var al = ar.length;
    for (var i = 0; i < hl; i++) {
      hs.push(ar[Math.floor(Math.random() * al)]);
    }

    return hs.join("");
  }

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
    if (!time) return '';
    else {
        const date = new Date(time);
        const o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'H+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds(),
            'q+': Math.floor((date.getMonth() + 3) / 3),
            S: date.getMilliseconds(),
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length)
            );
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                fmt = fmt.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1
                        ? o[k]
                        : ('00' + o[k]).substr(('' + o[k]).length)
                );
            }
        }
        return fmt;
    }
}



