

<template>
  <div class="wrap-container sn-container">
    <div class="sn-content">
      <div class='box-title'>
        <el-popover
                placement="bottom"
                width="100%"
                trigger="click"
                v-model="showPopover"
                transition="el-zoom-in-top"
        >
          <div style="padding: 20px">
            <div class="p15 tc f16">
              时间筛选
            </div>
            <div>
              <el-date-picker
                      v-model="value"
                      type="datetimerange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions">
              </el-date-picker>
            </div>
            <div class="mt10 tr">
              <button class="btn-reset " @click="timeConfirm(0)">
                重置
              </button>
              <button class="btn-add" @click="timeConfirm(1)">
                确定
              </button>
            </div>
          </div>
          <!--          <el-button slot="reference">click 激活</el-button>-->
          <div slot="reference" class="box-i-time">
            <i  class="el-icon-time i-time"></i>
          </div>
        </el-popover>
        <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:260px;height:60px;">动销排行榜</dv-decoration-11></span>
      </div>
      <div class="top-right-tag">
        <div class="df mr10"><div>入库</div><div class="box-tag bgc-green"></div></div>
        <div class="df"><div>消耗</div><div class="box-tag bgc-red"></div></div>
      </div>
      <div class="sn-body1">
        <div class="wrap-container1">
          <dv-loading class="loading" v-show="loading"></dv-loading>
          <div class="chartsdom" id="chart_bar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {formatTime} from "../../util/util";

  var echarts = require('echarts/lib/echarts');
  // 引入柱状图
  require('echarts/lib/chart/bar');
  require('echarts/lib/chart/pie');
  import api from '../../util/extra-api'
export default {
  name: "szBar",
  data() {
    return {
      loading: true,
      end: 100,
      option: null,
      xAxisData: [],
      yData1: [],
      yData2: [],
      listData: null,
      value: '',
      startTime: '',
      endTime: '',
      showPopover: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

    }
  },
  mounted() {
    if (localStorage.getItem("time3")) {
      let time3 = JSON.parse(localStorage.getItem('time3'))
      this.value = [time3.startTime,time3.endTime]
      this.startTime = time3.startTime
      this.endTime = time3.endTime
    }
    this.getChart7();
  },
  methods: {
    timeConfirm(type){
      if(type === 1){
        this.startTime = formatTime(this.value[0],'yyyy-MM-dd HH:mm:ss')
        this.endTime = formatTime(this.value[1],'yyyy-MM-dd HH:mm:ss')
      }else{
        this.startTime = ''
        this.endTime = ''
        this.value = ['','']
      }
      let time1 = {
        startTime: this.startTime,
        endTime: this.endTime
      }
      localStorage.setItem('time3',JSON.stringify(time1))
      this.getChart7()
      this.showPopover = false
    },
    getChart7(){
      this.loading = true
      api.get('/v1/wx/companyScreen/chart7',{companyId: localStorage.getItem('company_id'),startTime: this.startTime,endTime: this.endTime,type: localStorage.getItem('type')}).then(res =>{
        if(res.code === 200){
          this.listData  = res.data
          // res.data.sort((a,b) => b.receiptAmount - a.receiptAmount)
          if(this.listData.length >= 12){
            this.end = 12 / this.listData.length * 100
          }else{
            this.end = 100
          }
          this.xAxisData = res.data.map(m => m.goodsName)
          this.yData1 = res.data.map(m => m.receiptAmount)
          this.yData2 = res.data.map(m => -m.consumptionAmount)
          this.loading = false
          this.getEchart();
        }
      })
    },
    getEchart() {
      let myChart = echarts.init(document.getElementById('chart_bar'));
      let barWidth = '10%';
      let dataCoord = [
        {coord: [0, 9]},
        {coord: [1, 12]},
        {coord: [2, 15]},
        {coord: [3, 18]},
        {coord: [4, 15]},
        {coord: [5, 12]},
        {coord: [6, 9]},
        {coord: [7, 12]},
        {coord: [8, 15]},
        {coord: [9, 18]},
        {coord: [10, 15]},
        {coord: [11, 12]},
      ];
      let dataCoord2 = [
        {coord: [0, -9]},
        {coord: [1, -12]},
        {coord: [2, -15]},
        {coord: [3, -18]},
        {coord: [4, -15]},
        {coord: [5, -12]},
        {coord: [6, -9]},
        {coord: [7, -12]},
        {coord: [8, -15]},
        {coord: [9, -18]},
        {coord: [10, -15]},
        {coord: [11, -12]},
      ];

      this.option = {
        dataZoom: [
          {
            type: "inside",
            show: true,
            start: 0,
            end: this.end,
            xAxisIndex: [0],
          }
        ],
        xAxis: {
          data: this.xAxisData,
          axisLabel: {
            color: '#fff',
            interval:0,
            // rotate: -10,
            formatter:function(value)
            {
              // debugger
              var ret = "";//拼接加\n返回的类目项
              var maxLength = 2;//每项显示文字个数
              var valLength = value.length;//X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1)//如果类目项的文字大于3,
              {
                for (var i = 0; i < 5; i++) {
                  var temp = "";//每次截取的字符串
                  var start = i * maxLength;//开始截取的位置
                  var end = start + maxLength;//结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }
                return ret;
              }
              else {
                return value;
              }
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(40, 103, 168, 0.3)',
            }
          },
        },

        yAxis: {
          type: 'value',
          name: '(kg)',
          nameGap: 10,
          nameTextStyle:{
            color: '#fff',
            fontSize: 14
          },
          axisLabel: {
            color: '#999',

          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(40, 103, 168, 0.3)'
            }
          },
        },
        grid: {
          top: 25,
          left: 20,
          right: 20,
          bottom: 30,
          containLabel: true
        },
        series: [
          {
            name: '正值柱状图',
            type: 'bar',
            stack: 'one',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 1,
                  color: 'rgba(0, 0, 0, 0)'
                },{
                  offset: 0.5,
                  color: '#466e71'
                },{
                  offset: 0,
                  color: '#eb9b44'
                }]),
                label: {
                  show: true,		//开启显示
                  position: 'top',	//在上方显示
                  textStyle: {	    //数值样式
                    color: 'white',
                    fontSize: 14
                  }
                }
              }
            },
            barWidth: barWidth,
            // markPoint: {
            //   symbol: 'circle',
            //   itemStyle: {
            //     normal: {
            //       color: '#eb9b44',
            //       shadowColor: '#eb9b44',
            //       shadowBlur: 20
            //     }
            //   },
            //   symbolSize: [10, 10], // 容器大小
            //   symbolOffset: [0, 0], // 位置偏移
            //   data: dataCoord,
            // },
            data: this.yData1
          },
          {
            name: '负值柱状图',
            type: 'bar',
            stack: 'one',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0, 0, 0, 0)'
                },{
                  offset: 0.5,
                  color: '#774a75'
                },{
                  offset: 1,
                  color: '#b34d69'
                }]),
                label: {
                  show: true,		//开启显示
                  position: 'bottom',	//在下方显示
                  formatter:function(param){
                    if (param.value != 0) return param.value;
                    else return '';
                  },
                  textStyle: {	    //数值样式
                    color: 'white',
                    fontSize: 14
                  }
                }
              }
            },
            barWidth: barWidth,
            // markPoint: {
            //   symbol: 'circle',
            //   itemStyle: {
            //     normal: {
            //       color: '#b34d69',
            //       shadowColor: '#b34d69',
            //       shadowBlur: 20
            //     }
            //   },
            //   symbolSize: [10, 10],
            //   symbolOffset: [0, 0],
            //   data: dataCoord2,
            // },
            data: this.yData2
          }
        ]
      };
      myChart.setOption(this.option, true);

      window.addEventListener('resize', () => {
        myChart.resize();
      });
    }
  },
  beforeDestroy() {

  }
};
</script>

<style lang="scss" scoped>
.sn-container {
  padding-top: 16px;
  left: 975px;
  top: 690px;
  width: 100%;
  height: 500px;
  .chartsdom {
    width: 100%;
    height: 95%;
  }
  .sn-body1{
    height: 500px!important;
    .wrap-container1{
      height: 100%;
    }
  }
}
.top-right-tag{
  position: absolute;
  display: flex;
  right: 50px;
  top: 50px;
  .box-tag{
    width: 30px;
    height: 20px;
    border-radius: 50px;
    margin-left: 5px;
  }
  .bgc-red{
    background-color: #b34d69;
  }
  .bgc-green{
    background-color: #eb9b44;
  }
}
.df{
  display: flex;
}
</style>
