

<template>
  <div class="wrap-container sn-container">
    <div class="sn-content">
      <div class='box-title'>
        <el-popover
                placement="bottom"
                width="100%"
                trigger="click"
                v-model="showPopover"
                transition="el-zoom-in-top"
        >
            <div style="padding: 20px">
                <div class="p15 tc f16">
                    时间筛选
                </div>
                <div>
                  <el-date-picker
                          v-model="value"
                          type="datetimerange"
                          align="right"
                          unlink-panels
                          :clearable="false"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :picker-options="pickerOptions">
                  </el-date-picker>
                </div>
                <div class="mt10 tr">
                    <button class="btn-reset " @click="timeConfirm(0)">
                        重置
                    </button>
                    <button class="btn-add" @click="timeConfirm(1)">
                        确定
                    </button>
                </div>
            </div>
<!--          <el-button slot="reference">click 激活</el-button>-->
            <div slot="reference" class="box-i-time">
                <i  class="el-icon-time i-time"></i>
            </div>
        </el-popover>
        <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:240px;height:60px;">重点物资成本费用排行榜</dv-decoration-11></span>
      </div>
      <div class="sn-body1">
        <div class="wrap-container">
          <div class="chartsdom" id="chart_bp"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/util/extra-api'
  import bus from '@/util/bus'
  import {formatTime} from '../../util/util'
  var echarts = require('echarts/lib/echarts');
  // 引入柱状图
  require('echarts/lib/chart/bar');
  let itemStyleList = [{
    color: '#d6d638'
  },{
    color: '#00c86c'
  },{
    color: '#07d8ff'
  },{
    color: '#0c71cf'
  },{
    color: '#d6d638'
  },{
    color: '#00c86c'
  },{
    color: '#d6d638'
  },{
    color: '#0c71cf'
  },{
    color: '#07d8ff'
  },{
    color: '#00c86c'
  },{
    color: '#d6d638'
  },
  ]
export default {
  name: "cakeLinkage",
  data() {
    return {
      option: null,
      dataMap: {},
      list: [],
      yData: [],
      visible: false,
        pickerOptions: {
            shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近一个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                }
            }, {
                text: '最近三个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                }
            }]
        },
        value: '',
        startTime: '',
        endTime: '',
        showPopover: false
    }
  },
  mounted() {
      if (localStorage.getItem("time1")) {
          let time1 = JSON.parse(localStorage.getItem('time1'))
          this.value = [time1.startTime,time1.endTime]
          this.startTime = time1.startTime
          this.endTime = time1.endTime
      }
    this.getChart5()
  },
  methods: {
      timeConfirm(type){
          if(type === 1){
              this.startTime = formatTime(this.value[0],'yyyy-MM-dd HH:mm:ss')
              this.endTime = formatTime(this.value[1],'yyyy-MM-dd HH:mm:ss')
          }else{
              this.startTime = ''
              this.endTime = ''
              this.value = ['','']
          }
          let time1 = {
              startTime: this.startTime,
              endTime: this.endTime
          }
          localStorage.setItem('time1',JSON.stringify(time1))
          this.getChart5()
          this.showPopover = false
      },
    getChart5(){
      api.get('/v1/wx/companyScreen/chart5',{companyId: localStorage.getItem('company_id'),startTime: this.startTime,endTime:this.endTime,type: localStorage.getItem('type')}).then(res =>{
        if(res.code === 200){
          this.list = res.data
          this.yData = res.data.map(m => m.goodsName)
          let index = 0
          this.xData = res.data.map(m => {
            return {
              value: m.money,
              itemStyle: itemStyleList[index++]
            }
          })
          this.getEchart();
        }
      })
    },
    // dataFormatter(obj) {
    //   let pList = this.yData;
    //   let temp;
    //   for (let y = 2016; y <= 2020; y++) {
    //     let max = 0;
    //     let sum = 0;
    //     temp = obj[y];
    //     for (let i = 0, l = temp.length; i < l; i++) {
    //       max = Math.max(max, temp[i]);
    //       sum += temp[i];
    //       obj[y][i] = {
    //         name: pList[i],
    //         value: temp[i]
    //       };
    //     }
    //     obj[y + 'max'] = Math.floor(max / 100) * 100;
    //     obj[y + 'sum'] = sum;
    //   }
    //   return obj;
    // },
    getEchart() {
      let myChart = echarts.init(document.getElementById('chart_bp'));
      this.option = {
        baseOption: {
          tooltip: {
            trigger: 'item'
          },
          // grid: {
          //   bottom: '20%',
          //   right: "42%"
          // },
          calculable: true,
          yAxis: [{
            type: 'category',
            data: this.yData,
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#B4B4B4',
              }
            },
              axisLabel: {
                  color: '#fff',
                  interval:0,
                  // rotate: -10,
                  formatter:function(value)
                  {
                      // debugger
                      var ret = "";//拼接加\n返回的类目项
                      var maxLength = 5;//每项显示文字个数
                      var valLength = value.length;//X轴类目项的文字个数
                      var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
                      if (rowN > 1)//如果类目项的文字大于3,
                      {
                          for (var i = 0; i < 2; i++) {
                              var temp = "";//每次截取的字符串
                              var start = i * maxLength;//开始截取的位置
                              var end = start + maxLength;//结束截取的位置
                              //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                              temp = value.substring(start, end) + "\n";
                              ret += temp; //凭借最终的字符串
                          }
                          return ret;
                      }
                      else {
                          return value;
                      }
                  }
              },
          }],
          xAxis: [{
            type: 'value',
            name: '(元)',
            nameTextStyle:{
              color: '#fff',
              fontSize: 14
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#B4B4B4'
              }
            },

          }],
          series: [{
            name: '物资',
            type: 'bar',
            barMaxWidth: 15,
            center: ['20%', '65%'],
            seriesLayoutBy: 'row',
            // markPoint: {
            //   symbol: 'pin',
            //   itemStyle: {
            //     normal: {
            //       color: '#eb9b44',
            //       shadowColor: '#eb9b44',
            //       shadowBlur: 15
            //     }
            //   },
            //   data: this.xData,
            // },
            data: this.xData,
            label: {
              show: true,
              position: 'right'
            },
          },
              {
                  name: '应用占比',
                  type: 'pie',
                  center: ['76%', '20%'],
                  radius: '28%',
                  z: 100
              }
          ]
        },
        options: [{
          series: [
            {
              data: this.xData,
            }]
        }]
      }
      myChart.setOption(this.option, true);

      window.addEventListener('resize', () => {
        myChart.resize();
      });
    }
  },
  beforeDestroy() {

  }
};
</script>

<style lang="scss" scoped>
.sn-container {
  padding-top: 16px;
  left: 50px;
  top: 1548px;
  width: 100%;
  height: 550px;
  .chartsdom {
    width: 100%;
    height: 100%;
  }
  .sn-body1 {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 500px!important;
    .wrap-container{
      height: 100%;
      width: 100%;
    }
  }
}
</style>
