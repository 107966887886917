// initial state
const state = {
    menuPage: {
      pager_offset: '0',
        pager_openset:'10',
        menu_level:"1",
        menu_name:''
    },
    menuPage1: {
      pager_offset: '0',
        pager_openset:'10',
        menu_level:"2",
        menu_parent_id:'',
        menu_name:''
    },
  }
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    setNewPage ({ commit }, newValue) {
      commit('setNewPage', newValue)
    },
    setNewPage1 ({ commit }, newValue) {
      commit('setNewPage1', newValue)
    },
  }

  
  // mutations
  const mutations = {
    setNewPage (state, newValue) {
      state.menuPage.pager_offset= newValue.pager_offset
      state.menuPage.menu_level= newValue.menu_level
      state.menuPage.menu_name= newValue.menu_name
    },
    setNewPage1 (state, newValue) {
      state.menuPage1.pager_offset= newValue.pager_offset
      state.menuPage1.menu_level= newValue.menu_level
      state.menuPage1.menu_parent_id= newValue.menu_parent_id
      state.menuPage1.menu_name= newValue.menu_name
    },
  }

  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  