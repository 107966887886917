// initial state
const state = {
    patrolPage: {
      pager_offset: '0',
        pager_openset:'10',
        patrol_time:'',
        patrol_state:'',
        patrol_type:''
    },
    patrolPage1: {
      pager_offset: '0',
        pager_openset:'10',
        patrol_time:'',
        patrol_state:'',
        patrol_type:'2'
    },
    patrolPage2: {
      pager_offset: '0',
        pager_openset:'10',
        patrol_time:'',
        patrol_state:'',
        patrol_type:'3'
    },
    disposePage: {
      pager_offset: '0',
        pager_openset:'10',
        dispose_name:'',
        state:'',
    },
    punishPage: {
      pager_offset: '0',
        pager_openset:'10',
        punish_name:'',
        state:'',
    },
  }
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    setNewPage ({ commit }, newValue) {
      commit('setNewPage', newValue)
    },
    setNewPage1 ({ commit }, newValue) {
      commit('setNewPage1', newValue)
    },
    setNewPage2 ({ commit }, newValue) {
      commit('setNewPage2', newValue)
    },
    setNewPage3 ({ commit }, newValue) {
      commit('setNewPage3', newValue)
    },
    setNewPage4 ({ commit }, newValue) {
      commit('setNewPage4', newValue)
    },
  }
  
  // mutations
  const mutations = {
    setNewPage (state, newValue) {
      state.patrolPage.pager_offset= newValue.pager_offset
      state.patrolPage.patrol_time=newValue.patrol_time==null?'':newValue.patrol_time
      state.patrolPage.patrol_state= newValue.patrol_state
      state.patrolPage.patrol_type= newValue.patrol_type
    },
    setNewPage1 (state, newValue) {
      state.disposePage.pager_offset= newValue.pager_offset
      state.disposePage.dispose_name= newValue.dispose_name
      state.disposePage.state= newValue.state
    },
    setNewPage2 (state, newValue) {
      state.punishPage.pager_offset= newValue.pager_offset
      state.punishPage.punish_name= newValue.punish_name
      state.punishPage.state= newValue.state
    },
    setNewPage3 (state, newValue) {
      state.patrolPage1.pager_offset= newValue.pager_offset
      state.patrolPage1.patrol_time= newValue.patrol_time==null?'':newValue.patrol_time
      state.patrolPage1.patrol_state= newValue.patrol_state
      state.patrolPage1.patrol_type= newValue.patrol_type
    },
    setNewPage4 (state, newValue) {
      state.patrolPage2.pager_offset= newValue.pager_offset
      state.patrolPage2.patrol_time= newValue.patrol_time==null?'':newValue.patrol_time
      state.patrolPage2.patrol_state= newValue.patrol_state
      state.patrolPage2.patrol_type= newValue.patrol_type
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
  