

import modal from './modal' // 自定义全局模态框
import sinan from './sinan' // 司南排名图
import seamless from './seamless' // 新闻无缝滚动
import seamless1 from './seamless1' // 新闻无缝滚动
import scrollArc from './scrollArc' // 滚动弧形线
import szBar from './szBar' // 双轴柱状图
import cakeLinkage from './cakeLinkage' // 柱饼组合联动
import szLinkage from './szLinkage' //
import pyramidTrend from './pyramidTrend' // 金字塔趋势
import haccpChart from './haccpChart' // haccp
import kpiChart from './kpiChart' // kpi
import fullScreen from './fullScreen' // 全屏
import threeDChart from './threeDChart' // 3d_chart
//import bottomLeftChart from './bottomLeftChart'

const components = {
  modal,
  sinan,
  seamless,
  seamless1,
  scrollArc,
  szBar,
  cakeLinkage,
  szLinkage,
  pyramidTrend,
  //bottomLeftChart
  haccpChart,
  kpiChart,
  fullScreen,
  threeDChart
};

const install = (Vue = {}) => {
  if (install.installed) return;
  Object.keys(components).forEach(component => {
    Vue.component(components[component].name, components[component]);
  });

  install.installed = true;
};

install.installed = false;

if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
  install.installed = true;
}

const Vcomp = {
  ...components,
  install
};


export default Vcomp
