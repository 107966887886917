<template>
    <div class="df">
        <div>
            <span class="text" style="font-size: 20px;color: white">{{ dateYear }} {{ dateDay }}</span>
        </div>
        <div v-if="showFull" class="full-btn" @click="handleFull">
            <img src="./img/fullScreen.png" width="40">
        </div>
        <div v-else class="full-btn" @click="cancelFull">
            <img src="./img/cancelFull.png" width="40">
        </div>
    </div>
</template>

<script>
    import bus from '@/util/bus'
    import {local,formatTime} from '@/util/util'
    export default {
        name: "fullScreen",
        props:{

        },
        data(){
            return{
                showFull: true,
                dateDay: null,
                dateYear: null,
                dateWeek: null,
                weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
            }
        },
        mounted() {
            this.timeFn()
            this.showFull = !document.fullscreenElement
        },
        methods:{
            timeFn() {
                this.timing = setInterval(() => {
                    this.dateDay = formatTime(new Date(), 'HH: mm: ss')
                    this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
                }, 100)
            },
            handleFull(){
                let el = document.documentElement;
                ( el.requestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen).call(el);
                bus.$emit('full',{full: true})
                bus.$emit('refreshChart',{show: true})
                this.showFull = false
            },
            cancelFull(){
                bus.$emit('full',{full: false})
                clearInterval(local.get('t'))
                local.del('t')
                let full = this.$route.query.fullScreen ? this.$route.query.fullScreen : null
                //console.log(full)
                this.$router.push(this.$route.name); //再跳转路由路径，query参数没带过去，所以被清除了
                if(!full){
                    location.reload()
                }
                bus.$off('fullScreen')
                const isFullScreen = document.fullscreenElement
                if(isFullScreen){
                    if (document.exitFullscreen) {
                        document.exitFullscreen()
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen()
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen()
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen()
                    }
                }
                this.showFull = true
            }
        }
    }
</script>

<style scoped>
    .full-btn{
        cursor: pointer;
        margin-top: -10px;
        margin-left: 20px;
    }
</style>
