<template>
  <div id="app">
    <!-- <keep-alive> -->
    <router-view  :key="key" ></router-view>
<!-- </keep-alive> -->

  </div>
</template>
<script>
export default {
  name: 'app',
  computed: {
     key() {
 return this.$route.name !== undefined? this.$route.name +new Date(): this.$route +new Date()
 }
  }
}
</script>
<style lang="scss">
body{padding-right:0 !important;}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
