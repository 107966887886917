

<template>
  <div class="wrap-container sn-container">
    <div class="sn-content">
      <div class='box-title'>
        <el-popover
                placement="bottom"
                width="100%"
                trigger="click"
                v-model="showPopover"
                transition="el-zoom-in-top"
        >
          <div style="padding: 20px">
            <div class="p15 tc f16">
              时间筛选
            </div>
            <div>
              <el-date-picker
                      v-model="value"
                      type="datetimerange"
                      align="right"
                      unlink-panels
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :picker-options="pickerOptions">
              </el-date-picker>
            </div>
            <div class="mt10 tr">
              <button class="btn-reset " @click="timeConfirm(0)">
                重置
              </button>
              <button class="btn-add" @click="timeConfirm(1)">
                确定
              </button>
            </div>
          </div>
          <!--          <el-button slot="reference">click 激活</el-button>-->
          <div slot="reference" class="box-i-time">
            <i  class="el-icon-time i-time"></i>
          </div>
        </el-popover>
        <span class="fs-xl text mx-2 "><dv-decoration-11 style="width:200px;height:60px;">报损报溢统计</dv-decoration-11></span>
      </div>
      <div class="top-right-tag">
        <div class="df mr10"><div>报溢</div><div class="box-tag bgc-green"></div></div>
        <div class="df"><div>报损</div><div class="box-tag bgc-red"></div></div>
      </div>
      <div class="sn-body">
        <div class="wrap-container">

          <div class="table">
            <table border="0" cellpadding="0" cellspacing="0" class="table-header">
              <tbody>
                <tr>
                  <td width="50%">
                    <span>商品信息</span>
                  </td>
                  <td width="30%">
                    <span>报 损(kg)</span>
                  </td>
                  <td width="20%">
                    <span>报 溢(kg)</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <vue-seamless-scroll :data="listData" class="seamless-warp" :class-option="optionSetting" >
              <table border="0" cellpadding="0" cellspacing="0" class="item">
                <tbody>
                  <tr v-for="(item, index) in listData" :key="index">
                    <td width="100%" class="title">
                      <span>{{ item.goodsInfo }}</span>
                    </td>
                    <td width="30%">
                      <span class="colorRed">{{ item.damageOfNum }}</span>
                    </td>
                    <td width="20%">
                      <span class="colorGreen" style="font-size: 16px">{{ item.overflowNum }}</span>
                    </td>
                  </tr>
                </tbody>
            </table>
            </vue-seamless-scroll>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import api from "../../util/extra-api";
import {formatTime} from "../../util/util";

export default {
  name: "seamless1",
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
     listData: [],
      value: [],
      startTime: '2021-12-17',
      endTime: '',
      showPopover: false,
      minTime:1639670400000,//时间戳，对应时间为：'2021-04-20 12:00:00'
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
        disabledDate: (time) => {
          //小于最小时间或者大于最大时间都不可选
          return time.getTime() < this.minTime
        }
      },

    }
  },
  computed: {
　　optionSetting () {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // autoPlay: false,
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  mounted() {
    if (localStorage.getItem("time4")) {
      let time4 = JSON.parse(localStorage.getItem('time4'))
      this.value = [time4.startTime,time4.endTime]
      this.startTime = time4.startTime
      this.endTime = time4.endTime
    }
    this.getChart4()
  },
  methods: {
    timeConfirm(type){
      if(type === 1){
        this.startTime = formatTime(this.value[0],'yyyy-MM-dd HH:mm:ss')
        this.endTime = formatTime(this.value[1],'yyyy-MM-dd HH:mm:ss')
      }else{
        this.startTime = '2021-12-17 00:00:00'
        this.endTime = ''
        this.value = ['2021-12-17 00:00:00','']
      }
      let time4 = {
        startTime: this.startTime,
        endTime: this.endTime
      }
      localStorage.setItem('time4',JSON.stringify(time4))
      this.getChart4()
      this.showPopover = false
    },
    getChart4(){
      api.get('/v1/wx/companyScreen/chart4',{companyId: localStorage.getItem('company_id'),startTime: this.startTime,endTime: this.endTime,type:localStorage.getItem('type')}).then(res =>{
        if(res.code === 200){
          this.listData  = res.data
        }
      })
    },
  },
  beforeDestroy() {

  }
};
</script>

<style lang="scss" scoped>
.sn-container {
  padding-top: 16px;
  left: 1370px;
  top: 110px;
  %table-style {
    width: 100%;
    height: 35px;
    table-layout: fixed;
    tr {
      td {
        padding: 10px 5px;
        text-align: center;
        background-color: transparent;
        white-space: nowrap;
        overflow: hidden;
        color: #E2E5FF;
        font-size: 16px;
      }
    }
  }
  .table {
    .table-header {
      @extend %table-style;
    }
    .seamless-warp {
      height: 400px;
      overflow: hidden;
      visibility: visible;
      .colorRed {
        color: #FF4669;
      }
      .colorGreen {
        color: #45b97c;
      }
      .item {
        height: auto;
        @extend %table-style;
        tr {
          td {
            &.title {
              text-overflow: ellipsis;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
  .top-right-tag{
    position: absolute;
    display: flex;
    right: 50px;
    top: 50px;
    .box-tag{
      width: 30px;
      height: 20px;
      border-radius: 50px;
      margin-left: 5px;
    }
    .bgc-red{
      background-color: #FF4669;
    }
    .bgc-green{
      background-color: #45b97c;
    }
  }
  .df{
    display: flex;
  }
</style>
